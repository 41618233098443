import React from 'react';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

interface Props {}

type WithReduxConfig = {
  store: Store<unknown, AnyAction>;
  persistor: Persistor;
  initApp: () => void;
};

// Note that the {...(this.props as T)} assertion is needed because of a current bug in TS 3.2
// https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
const withRedux = <T extends Props>(
  Component: React.ComponentType,
  { store, persistor, initApp }: WithReduxConfig,
  Loader: React.ReactNode = null,
) =>
  class Enhance extends React.Component {
    public render() {
      return (
        <Provider store={store}>
          <PersistGate
            loading={Loader}
            persistor={persistor}
            onBeforeLift={initApp}
          >
            <Component {...(this.props as T)} />
          </PersistGate>
        </Provider>
      );
    }
  };

export default withRedux;
