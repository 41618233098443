import * as Yup from 'yup';

import { FieldName } from './ResetPassword.constants';

export const ValidationSchema = Yup.object().shape({
  [FieldName.Password]: Yup.string()
    .required('general.input.error.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{8,}$/,
      'Your password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter and one number',
    ),
  [FieldName.ConfirmPassword]: Yup.string()
    .required('general.input.error.required')
    .oneOf([Yup.ref(FieldName.Password), null], 'The passwords do not match'),
});
