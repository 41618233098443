import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Error = { name: string; message: string };

export type VerifyRequest = {
  username: string;
  code: string;
};

export type Auth = {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  user?: User;
};

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  country: string;
};

export type ConfirmPasswordData = {
  username: string;
  password: string;
  code: string;
};

export type SignupData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type SigninData = {
  email: string;
  password: string;
};

export interface AuthState {
  signup: {
    isLoading: boolean;
    error?: Error;
    finished: boolean;
  };
  signin: {
    isLoading: boolean;
    error?: Error;
  };
  forgotPassword: {
    isLoading: boolean;
    error?: Error;
    finished?: boolean;
  };
  confirmNewPassword: {
    isLoading: boolean;
    error?: Error;
    finished?: boolean;
  };
  verify: {
    isLoading: boolean;
    isVerified?: boolean;
    error?: Error;
  };
  auth?: Auth;
}

const initialState: AuthState = {
  signup: {
    isLoading: false,
    error: undefined,
    finished: false,
  },
  signin: {
    isLoading: false,
    error: undefined,
  },
  forgotPassword: {
    isLoading: false,
    error: undefined,
    finished: undefined,
  },
  confirmNewPassword: {
    isLoading: false,
    error: undefined,
    finished: undefined,
  },
  verify: {
    isLoading: false,
    isVerified: undefined,
    error: undefined,
  },
  auth: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    AUTH_RESET: state => {
      state.signup.isLoading = false;
      state.signup.error = undefined;
      state.signup.finished = false;
      state.signin.isLoading = false;
      state.signin.error = undefined;
      state.forgotPassword.isLoading = false;
      state.forgotPassword.finished = undefined;
      state.forgotPassword.error = undefined;
      state.confirmNewPassword.isLoading = false;
      state.confirmNewPassword.finished = undefined;
      state.confirmNewPassword.error = undefined;
    },
    AUTH_SIGNOUT_START: () => {},
    AUTH_SIGNOUT_SUCCESS: state => {
      state.auth = undefined;
    },
    AUTH_SIGNIN_START: (state, _: PayloadAction<SigninData>) => {
      state.signin.isLoading = true;
      state.signin.error = undefined;
    },
    AUTH_SIGNIN_FAIL: (state, { payload }: PayloadAction<Error>) => {
      state.signin.isLoading = false;
      state.signin.error = payload;
      state.auth = undefined;
    },
    AUTH_SIGNIN_SUCCESS: state => {
      state.signin.isLoading = false;
      state.signin.error = undefined;
    },
    AUTH_SIGNUP_START: (state, _: PayloadAction<SignupData>) => {
      state.signup.isLoading = true;
      state.signup.error = undefined;
      state.signup.finished = false;
    },
    AUTH_SIGNUP_FAIL: (state, { payload }: PayloadAction<Error>) => {
      state.signup.isLoading = false;
      state.signup.error = payload;
      state.signup.finished = false;
    },
    AUTH_SIGNUP_SUCCESS: state => {
      state.signup.isLoading = false;
      state.signup.error = undefined;
      state.signup.finished = true;
    },
    AUTH_SET: (state, { payload }: PayloadAction<Auth>) => {
      state.auth = payload;
    },
    AUTH_USER_SET: (state, { payload }: PayloadAction<User>) => {
      if (state.auth) {
        state.auth.user = payload;
      }
    },
    AUTH_VERIFY_START: (state, _: PayloadAction<VerifyRequest>) => {
      state.verify.isVerified = undefined;
      state.verify.isLoading = true;
      state.verify.error = undefined;
    },
    AUTH_VERIFY_SUCCESS: state => {
      state.verify.isVerified = true;
      state.verify.isLoading = false;
      state.verify.error = undefined;
    },
    AUTH_VERIFY_FAIL: (state, { payload }: PayloadAction<Error>) => {
      state.verify.isVerified = undefined;
      state.verify.isLoading = false;
      state.verify.error = payload;
    },
    AUTH_FORGOT_PASSWORD_START: (
      state,
      _: PayloadAction<{ email: string }>,
    ) => {
      state.forgotPassword.finished = undefined;
      state.forgotPassword.isLoading = true;
      state.forgotPassword.error = undefined;
    },
    AUTH_FORGOT_PASSWORD_SUCCESS: state => {
      state.forgotPassword.finished = true;
      state.forgotPassword.isLoading = false;
      state.forgotPassword.error = undefined;
    },
    AUTH_FORGOT_PASSWORD_FAIL: (state, { payload }: PayloadAction<Error>) => {
      state.forgotPassword.finished = undefined;
      state.forgotPassword.isLoading = false;
      state.forgotPassword.error = payload;
    },
    AUTH_CONFIRM_NEW_PASSWORD_START: (
      state,
      _: PayloadAction<ConfirmPasswordData>,
    ) => {
      state.confirmNewPassword.isLoading = true;
      state.confirmNewPassword.error = undefined;
      state.confirmNewPassword.finished = undefined;
    },
    AUTH_CONFIRM_NEW_PASSWORD_FAIL: (
      state,
      { payload }: PayloadAction<Error>,
    ) => {
      state.confirmNewPassword.isLoading = false;
      state.confirmNewPassword.error = payload;
      state.confirmNewPassword.finished = false;
    },
    AUTH_CONFIRM_NEW_PASSWORD_SUCCESS: state => {
      state.confirmNewPassword.isLoading = false;
      state.confirmNewPassword.error = undefined;
      state.confirmNewPassword.finished = true;
    },
  },
});

export default authSlice;

export const {
  AUTH_RESET: authReset,
  AUTH_FORGOT_PASSWORD_START: authForgotPasswordStart,
  AUTH_FORGOT_PASSWORD_SUCCESS: authForgotPasswordSuccess,
  AUTH_FORGOT_PASSWORD_FAIL: authForgotPasswordFail,
  AUTH_CONFIRM_NEW_PASSWORD_START: authConfirmNewPasswordStart,
  AUTH_CONFIRM_NEW_PASSWORD_SUCCESS: authConfirmNewPasswordSuccess,
  AUTH_CONFIRM_NEW_PASSWORD_FAIL: authConfirmNewPasswordFail,
  AUTH_SIGNOUT_START: authSignoutStart,
  AUTH_SIGNOUT_SUCCESS: authSignoutSuccess,
  AUTH_SIGNIN_START: authSigninStart,
  AUTH_SIGNIN_FAIL: authSigninFail,
  AUTH_SIGNIN_SUCCESS: authSigninSuccess,
  AUTH_SIGNUP_START: authSignupStart,
  AUTH_SIGNUP_FAIL: authSignupFail,
  AUTH_SIGNUP_SUCCESS: authSignupSuccess,
  AUTH_VERIFY_START: authVerifyStart,
  AUTH_VERIFY_FAIL: authVerifyFail,
  AUTH_VERIFY_SUCCESS: authVerifySuccess,
  AUTH_SET: setAuth,
  AUTH_USER_SET: setUser,
} = authSlice.actions;
