import { createStyles, ExtendButtonBase, withStyles } from '@material-ui/core';
import MUIButton, { ButtonTypeMap } from '@material-ui/core/Button';

const Button03 = withStyles(
  theme =>
    createStyles({
      textSizeLarge: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      },
      textSizeSmall: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
      root: {
        fontWeight: 'normal',
        backgroundColor: theme.palette.neutral01[500],
        color: theme.palette.neutral02[300],
        '&:hover': {
          backgroundColor: theme.palette.neutral02[600],
          color: theme.palette.neutral02[300],
          '@media (hover: none)': {
            backgroundColor: theme.palette.neutral01[500],
          },
        },
        '&:disabled': {
          backgroundColor: theme.palette.neutral02[500],
          color: theme.palette.neutral02[300],
        },
      },
    }),
  { name: 'Button' },
)(MUIButton) as ExtendButtonBase<ButtonTypeMap<{}, 'button'>>;
// TODO: fix typing with generic in withStyles.

export default Button03;
