import { AxiosRequestConfig } from 'axios';

import env from 'config';

const success = (connection: AxiosRequestConfig) => {
  // Because the api needs a trailing slash, otherwise it will direct to the frontend 🤷‍♂️
  // For more info @jorenvanhemelrijk
  if (
    connection.baseURL === env.API_BASE &&
    !connection.url?.includes('http') &&
    connection.url &&
    connection.url[connection.url.length - 1] !== '/'
  ) {
    connection.url += '/';
  }
  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
