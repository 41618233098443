import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { apiService, gaService } from 'services';
import { Gender, ModelRequestBody } from 'services/api/model.definitions';

import { getUser } from 'store/auth/auth.selectors';
import { setUser, User } from 'store/auth/auth.slice';
import { createModelStart } from 'store/model/model.slice';

import {
  MeasurementFormData,
  updateMeasurementsForm,
} from './measurement.slice';
import { getMeasurements } from './measurements.selectors';

export function* createModelFromMeasurementsFlow() {
  const measurements: ReturnType<typeof getMeasurements> = yield select(
    getMeasurements,
  );

  if (measurements.aboutMeForm && measurements.measurementsForm) {
    const { age, gender, weight, height, cup } = measurements.aboutMeForm;
    const { waist, bust, hips } = measurements.measurementsForm;

    let g: Gender = 'X';
    if (gender === 'male') {
      g = 'M';
    } else if (gender === 'female') {
      g = 'F';
    }

    const requestBody: ModelRequestBody = {
      age: Number(age),
      gender: g,
      weight: Number(weight),
      height: Number(height),
      cup,
      sizeBust: Number(bust),
      sizeHip: Number(hips),
      sizeWaist: Number(waist),
    };

    yield put(createModelStart(requestBody));
  }
}

function* updateMeasurementsFormFlow(_: PayloadAction<MeasurementFormData>) {
  try {
    const measurements: ReturnType<typeof getMeasurements> = yield select(
      getMeasurements,
    );

    // Set analytics user properties
    if (measurements.aboutMeForm && measurements.measurementsForm) {
      yield call(
        [gaService, gaService.setUserAge],
        Number(measurements.aboutMeForm.age),
      );
      yield call(
        [gaService, gaService.setUserCountry],
        measurements.aboutMeForm.country,
      );
      yield call(
        [gaService, gaService.setUserGender],
        measurements.aboutMeForm.gender,
      );
    }

    yield call(createModelFromMeasurementsFlow);

    const user: SagaReturnType<typeof getUser> = yield select(getUser);

    // Check if we have a user. We might need to update the country
    if (user) {
      const measurements: ReturnType<typeof getMeasurements> = yield select(
        getMeasurements,
      );

      if (
        measurements.aboutMeForm?.country &&
        measurements.aboutMeForm?.country !== user.country
      ) {
        const country = measurements.aboutMeForm.country;

        const {
          data: userResponse,
        }: SagaReturnType<typeof apiService.updateUser> = yield call(
          [apiService, apiService.updateUser],
          {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            country,
          },
        );

        const newUser: User = {
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          email: userResponse.email,
          id: userResponse.id,
          country: userResponse.country,
        };

        yield put(setUser(newUser));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export default function*() {
  yield all([takeLatest(updateMeasurementsForm, updateMeasurementsFormFlow)]);
}
