import React from 'react';

interface Props {}

// Note that the {...(props as T)} assertion is needed because of a current bug in TS 3.2
// https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
const withBoundary = <T extends Props>(
  BoundaryComponent: React.ComponentType,
) => (Component: React.ComponentType<T>) => (props: Props) => (
  <BoundaryComponent>
    <Component {...(props as T)} />
  </BoundaryComponent>
);

export default withBoundary;
