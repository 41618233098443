import { getBrands } from './brands.api';
import { createModel, createUserModel } from './model.api';
import { getSizeAdvice } from './size-advice.api';
import { getUser, updateUser } from './user.api';

interface Options<ApiInstance> {
  api: ApiInstance;
}

class RestApiService<ApiInstance> {
  public api: ApiInstance;

  constructor(options: Options<ApiInstance>) {
    this.api = options.api;
  }

  // MODEL
  public createModel = createModel;
  public createUserModel = createUserModel;

  // BRANDS
  public getBrands = getBrands;

  // SIZE ADVICE
  public getSizeAdvice = getSizeAdvice;

  // USER
  public getUser = getUser;
  public updateUser = updateUser;
}

export default RestApiService;
