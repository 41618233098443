import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserCookies = {
  essential: boolean;
  analytics?: boolean;
  marketing?: boolean;
};

export interface PreferencesState {
  cookies: UserCookies;
}

const initialState: PreferencesState = {
  cookies: { essential: true },
};

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    SET_COOKIES: (state, { payload }: PayloadAction<UserCookies>) => {
      state.cookies = payload;
    },
  },
});

export default preferencesSlice;

export const { SET_COOKIES: setCookies } = preferencesSlice.actions;
