import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import { apiService } from 'services';
import { SizeAdviceRequestBody } from 'services/api/size-advice.definitions';

import {
  getSizeAdviceFail,
  getSizeAdviceStart,
  getSizeAdviceSuccess,
} from './side-advice.slice';

function* getSizeAdviceFlow({ payload }: PayloadAction<SizeAdviceRequestBody>) {
  try {
    const response: SagaReturnType<typeof apiService.getSizeAdvice> = yield call(
      [apiService, apiService.getSizeAdvice],
      payload,
    );

    yield put(
      getSizeAdviceSuccess({ id: payload.brandId, advice: response.data }),
    );
  } catch (e) {
    yield put(getSizeAdviceFail(payload.brandId));
  }
}

export default function*() {
  yield all([takeLatest(getSizeAdviceStart, getSizeAdviceFlow)]);
}
