import * as Sentry from '@sentry/browser';
import React, { Component, ErrorInfo } from 'react';

interface Props {}

class ErrorBoundary extends Component<Props> {
  public state = { hasError: false };

  public static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // render any custom fallback UI
      return <h1>Something went wrong...</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
