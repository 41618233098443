import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { Router } from 'react-router-dom';

import { showConsent } from 'store/preferences/preferences.selectors';

import history from 'packages/history';

import { isReactSnap } from 'utils/env';

import RouteBoundary from 'components/@boundaries/RouteBoundary';
import { RouteProtected, RoutePublic } from 'components/@common/Route';
import Consent from 'components/Consent';
import ScrollToTop from 'components/ScrollToTop';

import routes from './routes';

const NotFound = React.lazy(() =>
  import('./not-found' /* webpackChunkName: "not-found" */),
);

const Pages = () => {
  const showCookies = useSelector(showConsent);

  return (
    <RouteBoundary>
      <Router history={history}>
        <ScrollToTop />
        <Suspense fallback={null}>
          <Switch>
            {routes.map(route => {
              const routeProps = {
                key: route.path,
                exact: route.exact,
                path: route.path,
                component: route.component,
                authenticated: route.authenticated,
              };

              return route.authenticated ? (
                <RouteProtected {...routeProps} />
              ) : (
                <RoutePublic {...routeProps} />
              );
            })}
            <RoutePublic component={NotFound} />
          </Switch>
          {showCookies && !isReactSnap() && <Consent open={showCookies} />}
        </Suspense>
      </Router>
    </RouteBoundary>
  );
};

export default Pages;
