import * as Sentry from '@sentry/browser';
import _get from 'lodash/get';
import React, { Component, ErrorInfo } from 'react';

interface Props {}
interface State {
  hasError: boolean;
  error?: {};
}

class ErrorBoundary extends Component<Props> {
  public state: State = { hasError: false, error: undefined };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  public render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    const message = _get(error, 'message');

    if (hasError) {
      // render any custom fallback UI
      return (
        <React.Fragment>
          <h1>Whoopsie</h1>
          {message && <p>{message}</p>}
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </React.Fragment>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
