import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Brand, Gender } from 'services/api/brands.definitions';

export interface BrandsState {
  brands: Brand[];
  isLoading: boolean;
  hasError: boolean;
}

const initialState: BrandsState = {
  isLoading: false,
  hasError: false,
  brands: [],
};

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    GET_BRANDS_START: (state, _: PayloadAction<Gender>) => {
      state.isLoading = true;
      state.hasError = false;
    },
    GET_BRANDS_FAIL: state => {
      state.isLoading = false;
      state.hasError = true;
    },
    GET_BRANDS_SUCCESS: (state, { payload }: PayloadAction<Brand[]>) => {
      state.isLoading = false;
      state.hasError = false;
      state.brands = payload;
    },
  },
});

export default brandsSlice;

export const {
  GET_BRANDS_START: getBrandsStart,
  GET_BRANDS_FAIL: getBrandsFail,
  GET_BRANDS_SUCCESS: getBrandsSuccess,
} = brandsSlice.actions;
