export const awsErrorForName = (name?: string) => {
  switch (name) {
    case 'UsernameExistsException':
      return 'auth.error.userexist';
    case 'UserNotFoundException':
      return 'auth.error.usernotexist';
    case 'UserNotConfirmedException':
      return 'auth.error.usernotconfirmed';
  }
};
