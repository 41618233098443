import { AxiosRequestConfig } from 'axios';

import store from 'store';
import { getIdToken } from 'store/auth/auth.selectors';

const success = (connection: AxiosRequestConfig) => {
  const token = getIdToken(store.store.getState());
  if (token && connection.authEnabled) {
    connection.headers.Authorization = `Bearer ${token}`;
  }
  return connection;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
