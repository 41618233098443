import './aws';

import { AxiosInstance } from 'axios';

import config from 'config';

import { GAService } from './analytics';
import RestApiService from './api';
import { createManager } from './api/axios';

export let apiService: RestApiService<AxiosInstance>;
export let gaService: GAService;

/**
 * initialize services
 */
export function initServices() {
  const apiManager = createManager({ baseURL: config.API_BASE });
  apiService = new RestApiService<AxiosInstance>({ api: apiManager });
  gaService = new GAService(config.ANALYTICS_GOOGLE_TRACKING_ID, {
    titleCase: false,
  });
}
