import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Route as RouteProps } from 'pages/routes.types';

class RoutePublic extends Component<RouteProps> {
  public render() {
    return <Route {...this.props} />;
  }
}

export default RoutePublic;
