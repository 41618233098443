import './normalize.css';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import StylesProvider from '@material-ui/styles/StylesProvider';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { theme01 as theme } from './../../theme';

export default <T extends {}>(Component: React.ComponentType<T>) =>
  React.forwardRef((props: T, ref) => (
    <>
      {/* Make sure Material UI styles are inject first */}
      <StylesProvider injectFirst>
        {/* Wrap with Material UI theme provider */}
        <MuiThemeProvider theme={theme}>
          {/* Wrap with Styled Components theme provider */}
          <StyledThemeProvider theme={theme}>
            <>
              {/* Apply Material UI baseline */}
              <CssBaseline />
              <Component ref={ref} {...props} />
            </>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  ));
