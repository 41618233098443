import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Button01 } from 'components/@common/Button';
import TextInput from 'components/@common/Input/TextInput';
import Message, { MessageVariant } from 'components/@common/Message/Message';

import { FieldName } from './ResetPassword.constants';
import { ValidationSchema } from './ResetPassword.validation';

interface Props {
  isLoading: boolean;
  errorMessage?: string;
  success?: boolean;
  onSubmit?: (password: string) => void;
}

type PasswordFormData = {
  password: string;
  confirmPassword: string;
};

const ResetPassword: React.FC<Props> = ({
  isLoading,
  success,
  errorMessage,
  onSubmit,
}) => {
  const { handleSubmit, errors, control } = useForm<PasswordFormData>({
    validationSchema: ValidationSchema,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmitForm = (data: PasswordFormData) => {
    onSubmit?.(data.password);
  };

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      bgcolor={{ xs: 'neutral01.500', sm: 'neutral03.100' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      <Box
        width={{ xs: '100%', sm: 360 }}
        height={{ xs: '100%', sm: 'auto' }}
        p={{ xs: 4, sm: 10 }}
        bgcolor={{ xs: 'transparent', sm: 'neutral01.500' }}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h2">
          <FormattedMessage id="auth.confirmpassword.title" />
        </Typography>
        <Box mt={4}>
          {Boolean(success) ? (
            <Box>
              <Message
                variant={MessageVariant.Success}
                messageId="auth.confirmpassword.success.body"
                bgColor="neutral03.100"
              />
              <Box mt={4}>
                <Button01 component={RouterLink} to={Routes.Me}>
                  <FormattedMessage id="auth.confirmpassword.button" />
                </Button01>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1">
                <FormattedMessage id="auth.confirmpassword.body" />
              </Typography>
              {!!errorMessage && (
                <Box>
                  <Box my={4}>
                    <Message
                      variant={MessageVariant.Error}
                      messageId={errorMessage}
                      bgColor="neutral03.100"
                    />
                  </Box>
                </Box>
              )}
              <form onSubmit={handleSubmit(handleSubmitForm)}>
                <Box mt={3} width="100%">
                  <Controller
                    as={
                      <TextInput
                        labelId="auth.confirmpassword.password.input.title"
                        error={!!errors.password}
                        errorMessage={errors.password?.message as string}
                        fullWidth
                        disabled={isLoading}
                        inputProps={
                          passwordVisible ? undefined : { type: 'password' }
                        }
                      />
                    }
                    name={FieldName.Password}
                    control={control}
                  />
                </Box>
                <Box mt={3} width="100%">
                  <Controller
                    as={
                      <TextInput
                        labelId="auth.confirmpassword.confirmpassword.input.title"
                        error={!!errors.confirmPassword}
                        errorMessage={errors.confirmPassword?.message as string}
                        fullWidth
                        disabled={isLoading}
                        inputProps={
                          passwordVisible ? undefined : { type: 'password' }
                        }
                      />
                    }
                    name={FieldName.ConfirmPassword}
                    control={control}
                  />
                </Box>
                <Box mt={1} textAlign="right">
                  <Link
                    component="button"
                    color="textSecondary"
                    variant="body2"
                    type="button"
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  >
                    <FormattedMessage
                      id={
                        passwordVisible
                          ? 'auth.password.hide.button'
                          : 'auth.password.show.button'
                      }
                    />
                  </Link>
                </Box>
                <Box mt={3}>
                  <Button01 type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <FormattedMessage id="auth.confirmpassword.save.button" />
                    )}
                  </Button01>
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
