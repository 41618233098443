import { Box, Typography } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum MessageVariant {
  Error = 'error',
  Info = 'info',
  Success = 'success',
}
interface Props {
  variant: MessageVariant;
  messageId: string;
  bgColor?: string;
}

const Message: React.FC<Props> = ({
  variant,
  messageId,
  bgColor = 'neutral01.500',
}) => {
  const renderIcon = () => {
    switch (variant) {
      case MessageVariant.Info:
        return <InfoOutlinedIcon fontSize="small" />;
      case MessageVariant.Error:
        return <ReportProblemOutlinedIcon fontSize="small" />;
      case MessageVariant.Success:
        return <CheckOutlinedIcon fontSize="small" />;
    }
  };

  const getColor = () => {
    switch (variant) {
      case MessageVariant.Info:
        return 'action01.400';
      case MessageVariant.Error:
        return 'error01.500';
      case MessageVariant.Success:
        return 'success01.500';
    }
  };

  return (
    <Box
      bgcolor={bgColor}
      padding={3}
      display="flex"
      color={getColor()}
      mb={2}
      textAlign="left"
    >
      {renderIcon()}
      <Box ml={2} alignSelf="center">
        <Typography>
          <FormattedMessage id={messageId} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Message;
