import { Theme } from '@material-ui/core';
import { rgba, transparentize } from 'polished';

const createOverrides = (theme: Theme): Theme['overrides'] => ({
  MuiCssBaseline: {
    '@global': {
      html: {
        // Set relative html font size based
        fontSize: `${(theme.typography.fontSize / 16) * 100}%`,
      },
      body: {
        color: theme.palette.neutral02[300],
        backgroundColor: theme.palette.neutral01[500],
      },
      button: {
        padding: 0,
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        '&:focus': {
          outline: 'none',
        },
      },
      a: {
        color: theme.palette.neutral02[400],
      },
    },
  },
  MuiContainer: {
    root: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: 0,
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      color: theme.palette.neutral01[500],
    },
  },
  MuiInputLabel: {
    shrink: {
      position: 'relative',
      transform: 'none',
    },
  },
  MuiInput: {
    formControl: {
      'label + &': {
        marginTop: theme.spacing(2),
      },
    },
  },
  MuiInputBase: {
    input: {
      '&::placeholder': {
        fontWeight: 'normal',
        color: theme.palette.neutral02[500],
      },
    },
  },
  MuiCheckbox: {
    colorPrimary: {
      color: theme.palette.neutral02[500],
      '&$checked': {
        color: theme.palette.action01[300],
      },
      '&$checked&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
      },
      '&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
        color: theme.palette.action01[300],
      },
    },
    colorSecondary: {
      color: theme.palette.neutral02[500],
      '&$checked': {
        color: theme.palette.action01[300],
      },
      '&$checked&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
      },
      '&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
        color: theme.palette.action01[300],
      },
    },
  },
  MuiRadio: {
    colorPrimary: {
      color: theme.palette.neutral02[500],
      '&$checked': {
        color: theme.palette.neutral02[300],
      },
      '&$checked&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
      },
      '&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
        color: theme.palette.action01[300],
      },
    },
    colorSecondary: {
      color: theme.palette.neutral02[500],
      '&$checked': {
        color: theme.palette.neutral02[300],
      },
      '&$checked&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
      },
      '&:hover': {
        backgroundColor: transparentize(0.9, theme.palette.action01[300]),
        color: theme.palette.action01[300],
      },
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      display: 'flex',
      alignItems: 'flex-start',
    },
    label: {
      marginTop: 11,
    },
  },
  MuiSwitch: {
    switchBase: {
      color: theme.palette.neutral01[500],
      '&$checked': {
        color: theme.palette.action01[300],
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.neutral03[200],
        opacity: 1,
      },
      '&$disabled': {
        color: theme.palette.neutral02[500],
        opacity: 1,
      },
      '&$disabled + $track': {
        backgroundColor: theme.palette.neutral03[200],
        opacity: 1,
      },
    },
    track: {
      backgroundColor: theme.palette.neutral03[200],
      opacity: 1,
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiLink: {
    root: {
      '&:hover, &:focus': {
        color: theme.palette.action01[400],
      },
      '&:disabled': {
        color: theme.palette.neutral02[500],
      },
      borderBottom: `1px solid`,
    },
    button: {
      borderBottom: `1px solid`,
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: 16,
      fontWeight: 'normal',
      color: theme.palette.neutral02[400],
      '&:hover': {
        color: theme.palette.action01[300],
      },
    },
  },
  MuiListItem: {
    gutters: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    root: {
      height: 48,
      fontSize: 16,
      fontWeight: 'normal',
      color: theme.palette.neutral02[400],
      '&:hover': {
        color: theme.palette.action01[300],
      },
      '&$selected': {
        color: theme.palette.action01[400],
        backgroundColor: rgba(220, 216, 208, 0.5),
      },
    },
  },
  MuiDivider: {
    inset: {
      backgroundColor: theme.palette.neutral03[200],
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 0,
    },
  },
  MuiAutocomplete: {
    listbox: {
      padding: 0,
    },
    option: {
      fontSize: 16,
      fontWeight: 'normal',
      color: theme.palette.neutral02[400],
      '&:hover': {
        color: theme.palette.action01[300],
      },
    },
  },
  MuiButton: {
    textSizeLarge: {
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    textSizeSmall: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    root: {
      '&:hover': {
        backgroundColor: theme.palette.action01[400],
        color: theme.palette.neutral01[500],
        '@media (hover: none)': {
          backgroundColor: theme.palette.action01[300],
        },
      },
      '&:disabled': {
        backgroundColor: theme.palette.neutral02[500],
        color: theme.palette.neutral01[500],
      },
      backgroundColor: theme.palette.action01[300],
      borderRadius: 0,
      color: theme.palette.neutral01[500],
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '16px',
      lineHeight: '24px',
      maxHeight: '40px',
    },
  },
});

export { createOverrides };
