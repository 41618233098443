import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SizeAdviceRequestBody,
  SizeGenderAdvice,
} from 'services/api/size-advice.definitions';

type SizeAdviceUI = {
  isLoading: boolean;
  hasError: boolean;
  advice?: SizeGenderAdvice;
};

export interface SizeAdviceState {
  brands: { [key: string]: SizeAdviceUI };
}

export const initialState: SizeAdviceState = {
  brands: {},
};

const sizeAdviceSlice = createSlice({
  name: 'size-advice',
  initialState,
  reducers: {
    GET_SIZE_ADVICE_START: (
      state,
      { payload }: PayloadAction<SizeAdviceRequestBody>,
    ) => {
      const id = payload.brandId;
      const advice = state.brands[id] || {};
      advice.isLoading = true;
      advice.hasError = false;
      state.brands[id] = advice;
    },
    GET_SIZE_ADVICE_FAIL: (state, { payload: id }: PayloadAction<number>) => {
      state.brands[id].isLoading = false;
      state.brands[id].hasError = true;
    },
    GET_SIZE_ADVICE_SUCCESS: (
      state,
      { payload }: PayloadAction<{ id: number; advice: SizeGenderAdvice }>,
    ) => {
      state.brands[payload.id].isLoading = false;
      state.brands[payload.id].hasError = false;
      state.brands[payload.id].advice = payload.advice;
    },
  },
});

export default sizeAdviceSlice;

export const {
  GET_SIZE_ADVICE_START: getSizeAdviceStart,
  GET_SIZE_ADVICE_FAIL: getSizeAdviceFail,
  GET_SIZE_ADVICE_SUCCESS: getSizeAdviceSuccess,
} = sizeAdviceSlice.actions;
