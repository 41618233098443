import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import { apiService } from 'services';
import { Gender } from 'services/api/brands.definitions';

import {
  getBrandsFail,
  getBrandsStart,
  getBrandsSuccess,
} from './brands.slice';

export function* getBrandsFlow(gender: Gender) {
  try {
    const response: SagaReturnType<typeof apiService.getBrands> = yield call(
      [apiService, apiService.getBrands],
      gender,
    );

    const sortedBrands = response.data.sort((a, b) => {
      const nameA = a.title.toLowerCase();
      const nameB = b.title.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    yield put(getBrandsSuccess(sortedBrands));
  } catch (e) {
    yield put(getBrandsFail());
  }
}

export function* getBrandsStartFlow({ payload }: PayloadAction<Gender>) {
  yield call(getBrandsFlow, payload);
}

export default function*() {
  yield all([takeLatest(getBrandsStart, getBrandsStartFlow)]);
}
