import { createStyles, ExtendButtonBase, withStyles } from '@material-ui/core';
import MUIButton, {
  ButtonProps,
  ButtonTypeMap,
} from '@material-ui/core/Button';

const Button02 = withStyles<string, {}, ButtonProps>(
  theme =>
    createStyles({
      textSizeLarge: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      },
      textSizeSmall: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
      root: {
        fontWeight: 'normal',
        backgroundColor: 'transparent',
        color: theme.palette.action01[300],
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.action01[400],
          border: `1px solid ${theme.palette.action01[400]}`,
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          color: theme.palette.neutral02[500],
          border: `1px solid ${theme.palette.neutral02[500]}`,
        },
        border: `1px solid ${theme.palette.action01[300]}`,
      },
    }),
  { name: 'Button' },
)(MUIButton) as ExtendButtonBase<ButtonTypeMap<{}, 'button'>>;
// TODO: fix typing with generic in withStyles.

export default Button02;
