import { Routes } from 'pages/routes.constants';

import Verify from './Verify.container';

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: Verify,
  exact: true,
  path: Routes.Verify,
  authenticated: false,
};
