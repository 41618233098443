import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import config from 'config';

export interface IntlState {
  locale: string;
}

const initialState: IntlState = {
  locale: config.DEFAULT_LOCALE,
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    LOCALE_UPDATE: (state, { payload }: PayloadAction<IntlState['locale']>) => {
      state.locale = payload;
      console.log(state);
    },
  },
});

export default intlSlice;
