import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gaService } from 'services';

import { getCookies } from 'store/preferences/preferences.selectors';
import { setCookies } from 'store/preferences/preferences.slice';

import ConsentForm from './ConsentForm';
import { CookiesFormData } from './ConsentForm.types';

const ConsentFormContainer: React.FC = () => {
  const dispatch = useDispatch();
  const consent = useSelector(getCookies);

  const handleSubmit = (values: CookiesFormData) => {
    values.analytics === true ? gaService.enable() : gaService.disable();
    dispatch(setCookies(values));
  };

  const defaultConsent: CookiesFormData = {
    essential: consent.essential,
    analytics: consent.analytics !== undefined ? consent.analytics : true,
    marketing: consent.marketing !== undefined ? consent.marketing : true,
  };

  return <ConsentForm consent={defaultConsent} onSubmit={handleSubmit} />;
};

export default ConsentFormContainer;
