import { Box, DialogProps, Typography } from '@material-ui/core';
import { ReactComponent as IcoLogo } from 'assets/svg/logo.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DialogStyled } from './ConsentOverlay.style';

const ConsentOverlay: React.FC<Pick<DialogProps, 'open'>> = ({
  open = true,
  children,
}) => (
  <DialogStyled
    aria-labelledby="consent"
    scroll="body"
    fullScreen
    open={open}
    disableBackdropClick
    disableEscapeKeyDown
    disablePortal
    keepMounted={false}
    TransitionProps={{ enter: false, unmountOnExit: true }}
    transitionDuration={{ appear: 0 }}
  >
    <Box
      display="flex"
      height="100%"
      justifyContent="center"
      bgcolor={{ xs: 'neutral01.500', sm: 'transparent' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      <Box
        bgcolor={{ xs: 'transparent', sm: 'neutral01.500' }}
        width={{ xs: '100%', sm: 480 }}
        p={{ xs: 4, sm: 10 }}
      >
        <Box textAlign="left" width="100%" mb={7}>
          <IcoLogo />
        </Box>

        <Box mb={6}>
          <Typography variant="h2" color="textPrimary" gutterBottom>
            <FormattedMessage id="consent.title" />
          </Typography>

          <Typography color="textSecondary">
            <FormattedMessage id="consent.description" />
          </Typography>
        </Box>

        {children}
      </Box>
    </Box>
  </DialogStyled>
);

export default ConsentOverlay;
