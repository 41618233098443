import { getDefaultMiddleware } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import config from 'config';

import { canMarketingUser } from 'store/preferences/preferences.selectors';
import { setCookies } from 'store/preferences/preferences.slice';
import { Store } from 'store/reducers';

export const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  collapsed: true,
  level: 'info',
});

const devMiddlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development' && typeof window !== undefined) {
  devMiddlewares.push(logger);
}

export const middlewares = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: {
      // redux persist does not pass the serializable check defined
      // Why should type be a string, or at least serializable:  https://redux.js.org/faq/actions/#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants
      // issue: https://github.com/rt2zz/redux-persist/issues/988
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  ...devMiddlewares,
];

// PAGE TRACK

let isGtmInitialized = false;
const initializeGtmMiddleware: Middleware<{}, Store> = ({
  getState,
}) => next => action => {
  const state = getState();
  let canTrack = canMarketingUser(state);

  const userSetcookies = action.type === setCookies.type;

  if (userSetcookies) {
    canTrack = action.payload.marketing;
  }
  if (!isGtmInitialized && canTrack) {
    isGtmInitialized = true;
    TagManager.initialize({
      gtmId: config.ANALYTICS_GTM_ID ?? '#',
      auth: config.ANALYTICS_GTM_AUTH,
      preview: config.ANALYTICS_GTM_PREVIEW,
    });
  }
  return next(action);
};

if (
  config.ANALYTICS_GTM_ID &&
  config.ANALYTICS_GTM_AUTH &&
  config.ANALYTICS_GTM_PREVIEW
) {
  middlewares.push(initializeGtmMiddleware);
}
