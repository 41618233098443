import { Store } from 'store/reducers';

export const getCookies = (state: Store) => state.preferences.cookies;
export const showConsent = (state: Store) =>
  state.preferences.cookies.analytics === undefined ||
  state.preferences.cookies.marketing === undefined;
export const canTrackUser = (state: Store) =>
  Boolean(state.preferences.cookies.marketing);
export const canMarketingUser = (state: Store) =>
  Boolean(state.preferences.cookies.marketing);
