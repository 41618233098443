import storage from 'redux-persist/lib/storage';

import config from 'config';

import { generateStore } from 'packages/redux';

import rootReducer from './reducers';
import sagas from './sagas';
import systemSlice from './system/system.slice';

const store = generateStore(sagas, rootReducer, {
  initApp: store => {
    store.dispatch(systemSlice.actions.SYSTEM_START());
  },
  enableDevTools: Boolean(config.ENABLE_DEV_TOOLS),
  persistConfig: {
    key: 'root',
    keyPrefix: '',
    storage,
    version: 1,
    debug: Boolean(config.ENABLE_DEV_TOOLS),
    whitelist: [
      'intl',
      'measurements',
      'model',
      'brands',
      'auth',
      'preferences',
    ],
    // add subsets to persist nested data
    transforms: [],
  },
});

export default store;
