import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { apiService } from 'services';
import { ModelRequestBody } from 'services/api/model.definitions';

import { userIsLoggedIn } from 'store/auth/auth.selectors';

import {
  createModelFail,
  createModelStart,
  createModelSuccess,
} from './model.slice';

function* createModelFlow({ payload }: PayloadAction<ModelRequestBody>) {
  try {
    const loggedIn: SagaReturnType<typeof userIsLoggedIn> = yield select(
      userIsLoggedIn,
    );

    if (loggedIn) {
      const response: SagaReturnType<typeof apiService.createUserModel> = yield call(
        [apiService, apiService.createUserModel],
        payload,
      );

      yield put(createModelSuccess(response.data));
    } else {
      const response: SagaReturnType<typeof apiService.createModel> = yield call(
        [apiService, apiService.createModel],
        payload,
      );

      yield put(createModelSuccess(response.data));
    }
  } catch (e) {
    yield put(createModelFail());
  }
}

export default function*() {
  yield all([takeLatest(createModelStart, createModelFlow)]);
}
