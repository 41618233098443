export default {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || '',
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',

  API_MAILCHIMP_URL: process.env.REACT_APP_MAILCHIMP_URL || '',

  // aws
  AWS_USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID || '',
  AWS_USER_POOL_CLIENT_ID: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID || '',
  AWS_REGION: process.env.REACT_APP_AWS_REGION || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',
  ANALYTICS_GTM_ID: process.env.REACT_APP_ANALYTICS_GTM_ID || '',
  ANALYTICS_GTM_AUTH: process.env.REACT_APP_ANALYTICS_GTM_AUTH || '',
  ANALYTICS_GTM_PREVIEW: process.env.REACT_APP_ANALYTICS_GTM_PREVIEW || '',

  // error tracking
  SENTRY: {
    dsn:
      'https://6e247c9c11c24e0a9ef64bcacf4380ff@o190235.ingest.sentry.io/5209207',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  },

  // dev
  PORT: process.env.PORT || 3000,
  ENABLE_DEV_TOOLS: process.env.REACT_APP_ENABLE_DEV_TOOLS || true,
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID || '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY || 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE || 'en-GB',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE || 'nl',
};
