import { Box, Link, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button01, Button02 } from 'components/@common/Button';

import { FieldName } from './ConsentForm.constants';
import { CookiesFormData } from './ConsentForm.types';

interface Props {
  consent: CookiesFormData;
  onSubmit: (data: CookiesFormData) => void;
}

const ConsentForm: React.FC<Props> = ({ onSubmit, consent }) => {
  const [collapsed, setCollapsed] = useState(true);

  const { handleSubmit, control } = useForm<CookiesFormData>({
    defaultValues: { ...consent },
  });

  const handleAcceptAll = () => {
    onSubmit({ essential: true, analytics: true, marketing: true });
  };

  return (
    <>
      <Button01 onClick={handleAcceptAll}>
        <FormattedMessage id="consent.accept_all.button" />
      </Button01>
      <Box mt={6}>
        <Link
          component="button"
          onClick={() => setCollapsed(!collapsed)}
          color="textSecondary"
          variant="body1"
        >
          <FormattedMessage
            id={
              collapsed
                ? 'consent.preferences.button'
                : 'consent.hide_preferences.button'
            }
          />
        </Link>
      </Box>
      {!collapsed && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={8} mb={8}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h3" color="textPrimary">
                <Box component="span" fontWeight="bold">
                  <FormattedMessage id="consent.option.essential.title" />
                </Box>
              </Typography>
              <Box ml={4}>
                <Controller
                  as={<Switch disabled checked />}
                  name={FieldName.Essential}
                  control={control}
                />
              </Box>
            </Box>
            <Typography color="textSecondary">
              <FormattedMessage id="consent.option.essential.description" />
            </Typography>
          </Box>

          <Box mb={8}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h3" color="textPrimary">
                <Box component="span" fontWeight="bold">
                  <FormattedMessage id="consent.option.analytics.title" />
                </Box>
              </Typography>
              <Box ml={4}>
                <Controller
                  as={<Switch autoFocus />}
                  name={FieldName.Analytics}
                  control={control}
                />
              </Box>
            </Box>
            <Typography color="textSecondary">
              <FormattedMessage id="consent.option.analytics.description" />
            </Typography>
          </Box>

          <Box mb={8}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h3" color="textPrimary">
                <Box component="span" fontWeight="bold">
                  <FormattedMessage id="consent.option.marketing.title" />
                </Box>
              </Typography>
              <Box ml={4}>
                <Controller
                  as={<Switch autoFocus />}
                  name={FieldName.Marketing}
                  control={control}
                />
              </Box>
            </Box>
            <Typography color="textSecondary">
              <FormattedMessage id="consent.option.marketing.description" />
            </Typography>
          </Box>

          <Button02 type="submit">
            <FormattedMessage id="consent.submit.button" />
          </Button02>
        </form>
      )}
    </>
  );
};

export default ConsentForm;
