import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

import { userIsLoggedIn } from 'store/auth/auth.selectors';

interface Props extends RouteProps {}

const RouteProtected: React.FC<Props> = props => {
  const isLoggedIn = useSelector(userIsLoggedIn);

  if (isLoggedIn) {
    return <Route {...props} />;
  } else {
    return <Redirect to={Routes.RedirectNotAuthorized} />;
  }
};

export default RouteProtected;
