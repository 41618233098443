import { PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { Routes } from 'pages/routes.constants';

import { apiService, gaService } from 'services';
import { PageView } from 'services/analytics/google-analytics/google-analytics';
import { UserResponse } from 'services/api/user.definitions';

import { createModelFromMeasurementsFlow } from 'store/measurement/measurement.saga';
import {
  AboutMeFormData,
  Gender,
  MeasurementFormData,
  setFormData,
} from 'store/measurement/measurement.slice';
import { getMeasurements } from 'store/measurement/measurements.selectors';
import { getModel } from 'store/model/model.selectors';
import { createModelSuccess, resetModel } from 'store/model/model.slice';

import history from 'packages/history';

import {
  authConfirmNewPasswordFail,
  authConfirmNewPasswordStart,
  authConfirmNewPasswordSuccess,
  authForgotPasswordFail,
  authForgotPasswordStart,
  authForgotPasswordSuccess,
  authSigninFail,
  authSigninStart,
  authSigninSuccess,
  authSignoutStart,
  authSignoutSuccess,
  authSignupFail,
  authSignupStart,
  authSignupSuccess,
  authVerifyFail,
  authVerifyStart,
  authVerifySuccess,
  ConfirmPasswordData,
  setAuth,
  setUser,
  SigninData,
  SignupData,
  User,
  VerifyRequest,
} from './auth.slice';

function* authSignupFlow({ payload }: PayloadAction<SignupData>) {
  try {
    const model: SagaReturnType<typeof getModel> = yield select(getModel);

    let attributes: { [key: string]: string } = {
      given_name: payload.firstName,
      family_name: payload.lastName,
      'custom:privacy_policy': 'true',
      'custom:terms_conditions': 'true',
      email: payload.email,
    };

    if (model && model.id) {
      attributes = { ...attributes, 'custom:model_id': model.id };
    }

    yield call([Auth, Auth.signUp], {
      username: payload.email,
      password: payload.password,
      attributes,
    });

    yield call([gaService, gaService.trackPageView], PageView.SignupSuccess);
    yield put(authSignupSuccess());
  } catch (e) {
    yield put(authSignupFail({ name: e.name, message: e.message }));
  }
}

function* createModelFlow() {
  const measurements: ReturnType<typeof getMeasurements> = yield select(
    getMeasurements,
  );

  // If we got a user, and we do have measurements, we should create a model on the user
  if (measurements.aboutMeForm && measurements.measurementsForm) {
    yield call(createModelFromMeasurementsFlow);
    // If we don't have measurements which is an edge case. We make sure we reset the model
  } else {
    yield put(resetModel());
  }
}

function* saveModelFlow(user: UserResponse) {
  const { model } = user;

  if (!model) {
    return;
  }

  // If the user has a model, set it in the model state
  yield put(createModelSuccess(model));

  // If the user has a model we convert the values to the form and save it in the measurements state
  let gender: Gender = 'other';
  if (model.gender === 'F') {
    gender = 'female';
  } else if (model.gender === 'M') {
    gender = 'male';
  }

  const aboutMe: AboutMeFormData = {
    age: model.age.toString(),
    gender,
    height: model.height.toString(),
    weight: model.weight.toString(),
    country: user.country,
    cup: model.cup,
  };

  const measurements: MeasurementFormData = {
    bust: model.sizeBust.toString(),
    waist: model.sizeWaist.toString(),
    hips: model.sizeHip.toString(),
  };

  // Set analytics user properties
  yield call([gaService, gaService.setUserAge], model.age);
  yield call([gaService, gaService.setUserGender], gender);

  yield put(setFormData({ aboutMe, measurements }));
}

export function* authGetUserFlow() {
  const {
    data: userResponse,
  }: SagaReturnType<typeof apiService.getUser> = yield call([
    apiService,
    apiService.getUser,
  ]);

  const user: User = {
    firstName: userResponse.firstName,
    lastName: userResponse.lastName,
    email: userResponse.email,
    id: userResponse.id,
    country: userResponse.country,
  };

  yield call([gaService, gaService.setUserCountry], userResponse.country);

  yield put(setUser(user));

  if (userResponse.model) {
    yield call(saveModelFlow, userResponse);
  } else {
    yield call(createModelFlow);
  }
}

function* authSigninFlow({ payload }: PayloadAction<SigninData>) {
  try {
    const response = yield call([Auth, Auth.signIn], {
      username: payload.email,
      password: payload.password,
    });

    const auth = {
      accessToken: response.signInUserSession.accessToken.jwtToken,
      refreshToken: response.signInUserSession.refreshToken.token,
      idToken: response.signInUserSession.idToken.jwtToken,
    };

    yield call([gaService, gaService.setUserStatus], 'loggedin');

    yield put(setAuth(auth));

    yield call(authGetUserFlow);

    yield put(authSigninSuccess());

    if (history.location.pathname === Routes.Root) {
      history.replace(Routes.Me);
    }
  } catch (e) {
    yield put(
      authSigninFail({
        name: e.name ?? 'error',
        message: e.message ?? 'error',
      }),
    );
  }
}

function* authSignoutFlow() {
  try {
    yield put(authSignoutSuccess());
    yield history.replace(Routes.Root);

    yield call([Auth, Auth.signOut], {});
  } catch (e) {
    console.error(e);
  }
}

function* authVerifyFlow({ payload }: PayloadAction<VerifyRequest>) {
  try {
    yield call([Auth, Auth.confirmSignUp], payload.username, payload.code);

    yield call([gaService, gaService.trackPageView], PageView.Verified);
    yield put(authVerifySuccess());
  } catch (e) {
    yield call([gaService, gaService.trackPageView], PageView.VerifiedFailed);
    yield put(
      authVerifyFail({
        name: e.name ?? 'error',
        message: e.message ?? 'error',
      }),
    );
  }
}

function* authForgotPasswordFlow({
  payload,
}: PayloadAction<{ email: string }>) {
  try {
    yield call([Auth, Auth.forgotPassword], payload.email);

    yield put(authForgotPasswordSuccess());
  } catch (e) {
    yield put(
      authForgotPasswordFail({
        name: e.name ?? 'error',
        message: e.message ?? 'error',
      }),
    );
  }
}

function* authConfirmNewPasswordFlow({
  payload,
}: PayloadAction<ConfirmPasswordData>) {
  try {
    yield call(
      [Auth, Auth.forgotPasswordSubmit],
      payload.username,
      payload.code,
      payload.password,
    );

    yield put(authConfirmNewPasswordSuccess());

    // Login the user
    yield put(
      authSigninStart({
        email: payload.username,
        password: payload.password,
      }),
    );
  } catch (e) {
    yield put(
      authConfirmNewPasswordFail({
        name: e.name ?? 'error',
        message: e.message ?? 'error',
      }),
    );
  }
}

export default function*() {
  yield all([
    takeLatest(authSignupStart, authSignupFlow),
    takeLatest(authSigninStart, authSigninFlow),
    takeLatest(authSignoutStart, authSignoutFlow),
    takeLatest(authVerifyStart, authVerifyFlow),
    takeLatest(authForgotPasswordStart, authForgotPasswordFlow),
    takeLatest(authConfirmNewPasswordStart, authConfirmNewPasswordFlow),
  ]);
}
