import { AxiosInstance } from 'axios';

import RestApiService from '.';
import { UserResponse } from './user.definitions';

export async function getUser(this: RestApiService<AxiosInstance>) {
  const response = await this.api.get<UserResponse>('/user', {
    authEnabled: true,
  });
  return response;
}

export async function updateUser(
  this: RestApiService<AxiosInstance>,
  user: Omit<UserResponse, 'id' | 'model'>,
) {
  const response = await this.api.put<UserResponse>('/user', user, {
    authEnabled: true,
  });
  return response;
}
