import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';

import config from 'config';

import { isProd } from 'utils/env';

export default (Component: typeof React.Component) => (props = {}) => {
  useEffect(() => {
    if (isProd()) {
      Sentry.init(config.SENTRY);
    }
  }, []);

  return <Component {...props} />;
};
