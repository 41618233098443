import qs from 'query-string';
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { getConfirmNewPassword } from 'store/auth/auth.selectors';
import { authConfirmNewPasswordStart, authReset } from 'store/auth/auth.slice';

import { awsErrorForName } from 'utils/aws';

import ResetPassword from './ResetPassword';

const ResetPasswordContainer: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const dispatch = useDispatch();
  const urlParams = qs.parse(location.search);
  const confirm = useSelector(getConfirmNewPassword);

  useLayoutEffect(() => {
    if (!urlParams.code || !urlParams.username) {
      history.replace(Routes.Root);
    } else {
      dispatch(authReset());
    }
  }, [dispatch, history, urlParams.code, urlParams.username]);

  const handleSubmit = (password: string) => {
    if (urlParams.code && urlParams.username) {
      const data = {
        username: urlParams.username.toString(),
        code: urlParams.code.toString(),
        password,
      };
      dispatch(authConfirmNewPasswordStart(data));
    }
  };
  const errorMessage = awsErrorForName(confirm.error?.name);

  return (
    <ResetPassword
      onSubmit={handleSubmit}
      isLoading={confirm.isLoading}
      success={confirm.finished}
      errorMessage={
        confirm.error ? errorMessage || 'auth.confirmpassword.error' : undefined
      }
    />
  );
};

export default ResetPasswordContainer;
