import { Routes } from 'pages/routes.constants';

import ResetPassword from './ResetPassword.container';

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: ResetPassword,
  exact: true,
  path: Routes.ResetPassword,
  authenticated: false,
};
