import { AxiosInstance } from 'axios';

import RestApiService from '.';
import { Model, ModelRequestBody } from './model.definitions';

const roundedData = (data: ModelRequestBody) => {
  return {
    age: Math.round(+data.age),
    gender: data.gender,
    height: Math.round(+data.height),
    weight: Math.round(+data.weight),
    sizeBust: Math.round(+data.sizeBust),
    sizeHip: Math.round(+data.sizeHip),
    sizeWaist: Math.round(+data.sizeWaist),
    cup: data.cup,
  };
};

export async function createModel(
  this: RestApiService<AxiosInstance>,
  model: ModelRequestBody,
) {
  const response = await this.api.post<Model>(
    '/models',
    roundedData(model),
    {},
  );
  return response;
}

export async function createUserModel(
  this: RestApiService<AxiosInstance>,
  model: ModelRequestBody,
) {
  const response = await this.api.post<Model>(
    '/user/models',
    roundedData(model),
    {
      authEnabled: true,
    },
  );
  return response;
}
