import { Action, combineReducers } from 'redux';

import auth, { authSignoutStart } from './auth/auth.slice';
import brands from './brands/brands.slice';
import intl from './intl/intl.slice';
import measurements, {
  initialState as measurementsInitialState,
} from './measurement/measurement.slice';
import model, { initialState as modelInitialState } from './model/model.slice';
import preferences from './preferences/preferences.slice';
import sizeAdvice, {
  initialState as sizeAdviceInitialState,
} from './size-advice/side-advice.slice';
import system from './system/system.slice';

export const reducers = {
  system: system.reducer,
  intl: intl.reducer,
  measurements: measurements.reducer,
  model: model.reducer,
  brands: brands.reducer,
  sizeAdvice: sizeAdvice.reducer,
  auth: auth.reducer,
  preferences: preferences.reducer,
};

const appReducer = combineReducers(reducers);

export type RootReducer = typeof appReducer;
export type Store = ReturnType<RootReducer>;

const rootReducer = (state: Store, action: Action) => {
  const newState = { ...state };

  if (action.type === authSignoutStart.type) {
    newState.measurements = measurementsInitialState;
    newState.model = modelInitialState;
    newState.sizeAdvice = sizeAdviceInitialState;
  }

  return appReducer(newState, action);
};

export default rootReducer;
