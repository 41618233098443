import React from 'react';

import { ConsentForm, ConsentOverlay } from './components';

interface Props {
  open: boolean;
}

const Consent: React.FC<Props> = ({ open }) => {
  return (
    <ConsentOverlay open={open}>
      <ConsentForm />
    </ConsentOverlay>
  );
};

export default Consent;
