// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

import config from 'config';

import MESSAGES_EN from './en.json';

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const messages: { [key: string]: { [key: string]: string } } = {
  'en-GB': MESSAGES_EN,
};

const locales = Object.keys(messages);

export { locales, messages, DEFAULT_LOCALE, DEFAULT_LOCALE_DATE };
