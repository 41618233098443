import { Store } from 'store/reducers';

export const getSignup = (state: Store) => state.auth.signup;
export const getSignin = (state: Store) => state.auth.signin;
export const getVerify = (state: Store) => state.auth.verify;
export const getForgotPassword = (state: Store) => state.auth.forgotPassword;
export const getConfirmNewPassword = (state: Store) =>
  state.auth.confirmNewPassword;

export const userIsLoggedIn = (state: Store) =>
  state.auth.auth !== undefined && state.auth.auth.user !== undefined;
export const getUser = (state: Store) => state.auth.auth?.user;

export const getIdToken = (state: Store) => state.auth.auth?.idToken;
export const getAccessToken = (state: Store) => state.auth.auth?.accessToken;
export const getRefreshToken = (state: Store) => state.auth.auth?.refreshToken;
