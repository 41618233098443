import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { gaService, initServices } from 'services';

import { authGetUserFlow } from 'store/auth/auth.saga';
import { userIsLoggedIn } from 'store/auth/auth.selectors';
import { getBrandsFlow } from 'store/brands/brands.saga';
import { getModel } from 'store/model/model.selectors';
import { canTrackUser } from 'store/preferences/preferences.selectors';

import systemSlice from './system.slice';

function* prefetchFlow() {
  const loggedIn: SagaReturnType<typeof userIsLoggedIn> = yield select(
    userIsLoggedIn,
  );
  const model: SagaReturnType<typeof getModel> = yield select(getModel);

  if (loggedIn) {
    yield call([gaService, gaService.setUserStatus], 'loggedin');
    yield call(authGetUserFlow);

    if (model) {
      yield call(getBrandsFlow, model.gender);
    }
  } else {
    yield call([gaService, gaService.setUserStatus], 'anonymous');
  }
}

export function* verifyAnalyticsTracking() {
  const canTrack: ReturnType<typeof canTrackUser> = yield select(canTrackUser);
  yield call([gaService, canTrack ? gaService.enable : gaService.disable]);
}

function* initApp() {
  /**
   * initialize all services
   * afterwards you can use them in the saga flows
   */
  yield call(initServices);
  yield call(verifyAnalyticsTracking);

  yield call(prefetchFlow);

  try {
    /**
     * system is ready -> time to render UI!
     */
    yield put(systemSlice.actions.SYSTEM_READY());
  } catch (e) {
    yield put(systemSlice.actions.SYSTEM_FAIL(e));
  }
}

export default function*() {
  yield all([takeLatest(systemSlice.actions.SYSTEM_START, initApp)]);
}
