import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { messages } from 'services/i18n/config';

import { getIntl } from 'store/intl/intl.selectors';
import { IntlState } from 'store/intl/intl.slice';
import { Store } from 'store/reducers';

interface Props {
  intl: IntlState;
}

const mapStateToProps = (state: Store) => ({
  intl: getIntl(state),
});

export default (C: React.StatelessComponent) =>
  connect(mapStateToProps)(
    class extends Component<Props> {
      public render() {
        const {
          intl: { locale },
        } = this.props;

        return (
          <IntlProvider
            key={locale}
            locale={locale}
            messages={messages[locale]}
          >
            <C {...this.props} />
          </IntlProvider>
        );
      }
    },
  );
