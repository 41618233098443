import { History, Location } from 'history';
import ReactGA, { InitializeOptions } from 'react-ga';

import { Routes } from 'pages/routes.constants';

import store from 'store';
import { hasModel } from 'store/model/model.selectors';

import history from 'packages/history';

import { getCountryLabel } from 'utils/country';

export enum PageView {
  LandingPage = 'landingpage',
  AboutYou = 'aboutyou',
  Measurements = 'measurements',
  AvatarGeneration = 'avatargeneration',
  ShavatarResults = 'shavatarresults',
  MyShavatar = 'my-shavatar',
  SizeAdvice = 'sizeadvice',
  Verified = 'verified',
  VerifiedFailed = 'verified-failed',
  ResetPassword = 'resetpassword',
  Signup = 'signup-form',
  SignupSuccess = 'signup-succes',
}

interface UrlState {
  step?: number;
  isFlow?: boolean;
}

class GAService {
  public key: string;

  constructor(key: string, options?: InitializeOptions) {
    this.key = key;
    if (key.length) {
      ReactGA.initialize(key, options);
    }

    this.trackPageFromLocation(history.location);

    history.listen(location => {
      this.trackPageFromLocation(location);
    });
  }

  enable() {
    // @ts-ignore
    window[`ga-disable-${this.key}`] = false;
  }

  disable() {
    // @ts-ignore
    window[`ga-disable-${this.key}`] = true;
  }

  trackPageFromLocation(
    location: Location<History.PoorMansUnknown | UrlState>,
  ) {
    let page;
    switch (location.pathname) {
      case Routes.Root:
        page = PageView.LandingPage;
        break;
      case Routes.Measurements:
        // Will be tracked in page
        return;
      case Routes.Me:
        const _hasModel = hasModel(store.store.getState());

        // If user doesn't has a model there will be a redirect so no need to track this
        if (!_hasModel) {
          return;
        }

        if ((location.state as UrlState | undefined)?.isFlow) {
          page = PageView.ShavatarResults;
        } else {
          page = PageView.MyShavatar;
        }
        break;
      case Routes.Brands:
        page = PageView.SizeAdvice;
        break;
      case Routes.Verify:
        // We handle the Verify page view track inside the verify saga
        return;
      case Routes.ResetPassword:
        page = PageView.ResetPassword;
        break;
    }

    if (page) {
      this.trackPageView(page);
    }
  }

  trackPageView(page: PageView) {
    ReactGA.pageview(page);
  }

  // Events

  trackGetStartedEvent(
    action: 'getstarted-header' | 'getstarted-body',
    hasModel: boolean,
  ) {
    if (hasModel) {
      ReactGA.event({
        category: 'getstarted-myshavatar',
        action,
      });
    } else {
      ReactGA.event({
        category: 'getstarted',
        action,
      });
    }
  }

  trackMailingListSignupEvent() {
    ReactGA.event({
      category: 'mailinglist',
      action: 'signup',
    });
  }

  trackFooterEvent(action: 'retailpartners' | 'contact') {
    ReactGA.event({
      category: 'footer',
      action,
    });
  }

  trackLegalEvent(
    action: 'terms' | 'privacy',
    label: 'mailinglist' | 'footer' | 'measurements',
  ) {
    ReactGA.event({
      category: 'legal',
      action,
      label,
    });
  }

  trackFeedbackEvent(action: 'sizeadvice' | 'shavatarresults') {
    ReactGA.event({
      category: 'feedback',
      action,
    });
  }

  trackUpdateMeasurementsEvent() {
    ReactGA.event({
      category: 'update',
      action: 'measurements',
    });
  }

  trackSelectBrandEvent(label: string) {
    ReactGA.event({
      category: 'brand',
      action: 'select',
      label,
    });
  }

  trackAccountEvent(
    action: 'login' | 'logout' | 'signup',
    label?: 'shavatarresults' | 'landingpage' | 'sizeadvice',
  ) {
    ReactGA.event({
      category: 'account',
      action,
      label,
    });
  }

  // User properties

  setUserStatus(status: 'loggedin' | 'anonymous') {
    ReactGA.set({ dimension1: status });
  }

  setUserAge(age: number) {
    ReactGA.set({ dimension2: age.toString() });
  }

  setUserGender(gender: 'male' | 'female' | 'other') {
    ReactGA.set({ dimension3: gender });
  }

  setUserCountry(countryCode: string) {
    ReactGA.set({ dimension4: getCountryLabel(countryCode)?.toLowerCase() });
  }
}

export default GAService;
