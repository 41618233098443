import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Gender = 'male' | 'female' | 'other';
type CupSize = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'larger';

export type AboutMeFormData = {
  age: string;
  gender: Gender;
  height: string;
  weight: string;
  cup?: CupSize;
  country: string;
};

export type MeasurementFormData = {
  bust: string;
  waist: string;
  hips: string;
};

export interface MeasurementState {
  aboutMeForm?: AboutMeFormData;
  measurementsForm?: MeasurementFormData;
}

export const initialState: MeasurementState = {};

const measurementSlice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {
    SET_FORMDATA: (
      state,
      {
        payload,
      }: PayloadAction<{
        aboutMe: AboutMeFormData;
        measurements: MeasurementFormData;
      }>,
    ) => {
      state.aboutMeForm = payload.aboutMe;
      state.measurementsForm = payload.measurements;
    },
    UPDATE_ABOUTME_FORM: (
      state,
      { payload }: PayloadAction<AboutMeFormData>,
    ) => {
      state.aboutMeForm = payload;
    },
    UPDATE_MEASUREMENTS_FORM: (
      state,
      { payload }: PayloadAction<MeasurementFormData>,
    ) => {
      state.measurementsForm = payload;
    },
  },
});

export default measurementSlice;

export const {
  UPDATE_ABOUTME_FORM: updateAboutMeForm,
  UPDATE_MEASUREMENTS_FORM: updateMeasurementsForm,
  SET_FORMDATA: setFormData,
} = measurementSlice.actions;
