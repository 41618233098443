import { compose } from 'redux';

import RootBoundary from 'components/@boundaries/RootBoundary';
import withBoundary from 'components/@boundaries/withBoundary';

import connectIntl from './intl';
import connectRedux from './redux';
import connectSentry from './sentry';
import connectTheme from './theme';

export default compose<React.ComponentType>(
  withBoundary(RootBoundary),
  connectSentry,
  connectTheme,
  connectRedux,
  connectIntl,
);
