export enum Routes {
  Root = '/',
  Measurements = '/measurements',
  Me = '/me',
  Brands = '/size-advice',
  // generic routes
  Verify = '/verify',
  ResetPassword = '/reset-password',
  RedirectNotAuthorized = '/',
}
