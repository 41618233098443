import { createStyles, Dialog, withStyles } from '@material-ui/core';
import { rgba } from 'polished';

export const DialogStyled = withStyles(theme =>
  createStyles({
    paperFullScreen: {
      backgroundColor: rgba(theme.palette.neutral03[100], 0.5),
    },
  }),
)(Dialog);
