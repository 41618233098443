import { Theme } from '@material-ui/core';

import { createCustomMuiTheme } from '../utils/create-mui-theme';
import * as colors from './colors';
import { createOverrides } from './overrides';

const CONFIG_TYPOGRAPHY = {
  BASE_FONT_SIZE: 16,
  RATIO_FACTOR: 1.15,
};

let THEME = createCustomMuiTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 768,
      md: 996,
      lg: 1200,
      xl: 1200,
    },
  },

  mixins: {},

  palette: {
    text: {
      primary: colors.neutral02[300],
      secondary: colors.neutral02[400],
    },
    error: { main: colors.error01[500] },
    ...colors,
  },

  shadows: ([
    'none',
    ...new Array(24).fill('0px 2px 2px rgba(58, 58, 65, 0.15)'),
  ] as unknown) as Theme['shadows'], // why: https://github.com/mui-org/material-ui/issues/8209

  shape: {
    borderRadius: 4,
    radius: factor => 4 * factor,
  },

  spacing: 4,

  typography: {
    fontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,
    htmlFontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,

    fontFamily: 'Nunito, sans-serif',

    h1: {
      fontSize: 36,
      lineHeight: '49px',
    },
    h2: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 19,
      lineHeight: '160%',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 12,
      lineHeight: '160%',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 16,
      lineHeight: '160%',
    },
    body2: {
      fontSize: 12,
      lineHeight: '160%',
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '160%',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: '160%',
      fontWeight: 'bold',
    },
    button: {
      fontWeight: 'normal',
    },
  },

  props: {
    MuiButton: { size: 'large' },
    MuiButtonBase: {
      disableRipple: false,
      disableTouchRipple: false,
    },
    MuiLink: {
      underline: 'none',
    },
    MuiSwitch: {
      color: 'default',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiTextField: {
      InputProps: {
        disableUnderline: true,
      },
      InputLabelProps: {
        disableAnimation: true,
        shrink: true,
      },
    },
  },
});

THEME.overrides = createOverrides(THEME);

console.info('MUI theme created', THEME);

export default THEME;
