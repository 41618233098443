import { configureStore } from '@reduxjs/toolkit';
import { Reducer, Store } from 'redux';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import { Saga } from 'redux-saga';

import { middlewares, sagaMiddleware } from './middlewares';

interface Options {
  enableDevTools: boolean;
  persistConfig: PersistConfig<any>; // eslint-disable-line
  initApp: (store: Store) => void;
}

const generateStore = (
  sagas: Saga,
  combinedReducers: Reducer,
  options: Options,
) => {
  const rootReducer = persistReducer(
    options.persistConfig,
    persistReducer(options.persistConfig, combinedReducers),
  );

  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
    devTools: options.enableDevTools,
  });

  sagaMiddleware.run(sagas);

  const persistor = persistStore(store);

  const initApp = () => options.initApp(store);

  return { store, persistor, initApp };
};

export default generateStore;
