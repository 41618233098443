import idToken from './request/id-token.interceptor';
import slash from './request/slash.interceptor';
import refresh from './response/refresh.interceptor';

// Request interceptors
const requestInterceptors = [idToken, slash];

// Response interceptors
const responseInterceptors = [refresh];

export { requestInterceptors, responseInterceptors };
