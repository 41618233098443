/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */

import brands from './brands';
import home from './home';
import me from './me';
import measurements from './measurements';
import resetPassword from './reset-password';
import verify from './verify';

export default [home, measurements, me, brands, verify, resetPassword];
