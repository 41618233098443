import { AxiosInstance } from 'axios';

import RestApiService from '.';
import { Brand, Gender } from './brands.definitions';

export async function getBrands(
  this: RestApiService<AxiosInstance>,
  gender?: Gender,
) {
  const response = await this.api.get<Brand[]>('/brands', {
    params: { gender },
  });
  return response;
}
