import qs from 'query-string';
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { getVerify } from 'store/auth/auth.selectors';
import { authVerifyStart } from 'store/auth/auth.slice';
import { getModel } from 'store/model/model.selectors';

import Verify from './Verify';

const VerifyContainer: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const dispatch = useDispatch();
  const urlParams = qs.parse(location.search);
  const verify = useSelector(getVerify);
  const model = useSelector(getModel);

  useLayoutEffect(() => {
    if (urlParams.code && urlParams.username) {
      const request = {
        code: urlParams.code.toString(),
        username: urlParams.username.toString(),
      };
      dispatch(authVerifyStart(request));
    } else {
      history.replace(Routes.Root);
    }
  }, [dispatch, history, urlParams.code, urlParams.username]);

  const errorMessage =
    verify.error?.message ===
    'User cannot be confirmed. Current status is CONFIRMED'
      ? 'auth.error.useralreadyconfirmed'
      : verify.error?.message;

  return (
    <Verify
      isLoading={verify.isLoading}
      verified={verify.isVerified}
      errorMessage={errorMessage}
      hasModel={Boolean(model)}
    />
  );
};

export default VerifyContainer;
