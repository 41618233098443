import { all } from 'redux-saga/effects';

import watchAuth from './auth/auth.saga';
import watchBrands from './brands/brands.saga';
import watchMeasurement from './measurement/measurement.saga';
import watchModel from './model/model.saga';
import watchSizeAdvice from './size-advice/size-advice.saga';
import watchSystem from './system/system.saga';

export default function*() {
  yield all([
    watchSystem(),
    watchAuth(),
    watchMeasurement(),
    watchModel(),
    watchBrands(),
    watchSizeAdvice(),
  ]);
}
