import { Color } from '@material-ui/core';

export const global01: Color = {
  50: '',
  100: '',
  200: '',
  300: '#0EA1A5',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const action01: Color = {
  50: '',
  100: '',
  200: '',
  300: '#BB8B2A',
  400: '#805F1D',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#fff',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral02: Color = {
  50: '',
  100: '',
  200: '',
  300: '#000',
  400: '#666666',
  500: '#BFBFBF',
  600: '#EBEAE9',
  700: '#F7F6F5',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral03: Color = {
  50: '',
  100: '#FAF9F8',
  200: '#E3DFD7',
  300: '#DCD8D0',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const error01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#E01212',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const success01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#54801D',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};
