import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Button01 } from 'components/@common/Button';
import Message, { MessageVariant } from 'components/@common/Message/Message';

interface Props {
  isLoading: boolean;
  errorMessage?: string;
  verified?: boolean;
  hasModel?: boolean;
}

const Verify: React.FC<Props> = ({
  isLoading,
  verified,
  errorMessage,
  hasModel,
}) => {
  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      bgcolor={{ xs: 'neutral01.500', sm: 'neutral03.100' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      {isLoading ? (
        <Box
          color="action01.300"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <Box
          width={{ xs: '100%', sm: 460 }}
          height={{ xs: '100%', sm: 'auto' }}
          p={{ xs: 4, sm: 10 }}
          bgcolor={{ xs: 'transparent', sm: 'neutral01.500' }}
          display="flex"
        >
          {!!verified && (
            <Box>
              <Typography variant="h2">
                <FormattedMessage id="auth.verify.title" />
              </Typography>
              <Box my={4}>
                <Typography variant="body1">
                  <FormattedMessage id="auth.verify.body" />
                </Typography>
              </Box>
              <Button01
                component={RouterLink}
                to={`${hasModel ? Routes.Me : Routes.Root}?login=1`}
              >
                <FormattedMessage id="auth.verify.button" />
              </Button01>
            </Box>
          )}
          {!!errorMessage && (
            <Box>
              <Typography variant="h2">
                <FormattedMessage id="auth.verify.error.title" />
              </Typography>
              <Box my={4}>
                <Message
                  variant={MessageVariant.Error}
                  messageId={errorMessage}
                  bgColor="neutral03.100"
                />
              </Box>
              <Button01
                component={RouterLink}
                to={`${hasModel ? Routes.Me : Routes.Root}?login=1`}
              >
                <FormattedMessage id="auth.verify.button" />
              </Button01>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Verify;
