import { Box, FormControl, IconButton, InputBase } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorLabel, Title, Type, Wrap } from './TextInput.style';
import { TextInputProps } from './types';

const TextInput: React.FC<TextInputProps> = React.forwardRef((props, ref) => {
  const {
    error,
    labelId,
    errorMessage,
    name,
    className,
    value = '',
    optional = false,
    onFocus,
    onBlur,
    fullWidth,
    onClear,
    endAdornment,
    ...rest
  } = props;

  const [focused, setFocused] = useState(false);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFocused(false);
    onBlur?.(e);
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFocused(true);
    onFocus?.(e);
  };

  const canClear = Boolean(onClear) && value.length > 0;

  return (
    <FormControl>
      {labelId && (
        <Box
          width={fullWidth ? '100%' : 160}
          display="flex"
          justifyContent="space-between"
        >
          <Title error={error} isFocused={focused}>
            <FormattedMessage id={labelId} />
          </Title>
          {optional && (
            <Type>
              <FormattedMessage id="general.input.optional" />
            </Type>
          )}
        </Box>
      )}
      <Wrap
        error={error}
        className={className}
        isFocused={focused}
        fullWidth={fullWidth}
        canClear={canClear}
      >
        <InputBase
          ref={ref}
          name={name}
          autoComplete="off"
          fullWidth={fullWidth}
          {...rest}
          value={value}
          onBlur={handleBlur}
          onFocus={handleFocus}
          endAdornment={
            canClear ? (
              <IconButton onClick={onClear} size="small">
                <ClearIcon />
              </IconButton>
            ) : (
              endAdornment
            )
          }
        />
      </Wrap>
      {error && errorMessage && (
        <ErrorLabel>
          <FormattedMessage
            {...(typeof errorMessage === 'string'
              ? { id: errorMessage }
              : { id: errorMessage.id, values: errorMessage.values })}
          />
        </ErrorLabel>
      )}
    </FormControl>
  );
});

export default TextInput;
