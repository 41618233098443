import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import store from 'store';
import { getRefreshToken } from 'store/auth/auth.selectors';
import { authSignoutStart, setAuth } from 'store/auth/auth.slice';

import { isDev } from 'utils/env';

type OpenRetryConnection = {
  resolve: (value: AxiosResponse) => void;
  reject: () => void;
  originalRequest: AxiosRequestConfig;
};

let openRetryConnections: OpenRetryConnection[] = [];
let isRefreshingToken = false;

const success = (response: AxiosResponse) => response;

const error = (connection: AxiosError<unknown>) => {
  const originalRequest = connection.config;

  if (connection.response?.status === 401 && !originalRequest.isRetry) {
    const refreshToken = getRefreshToken(store.store.getState());
    if (refreshToken) {
      return retryAfterRefresh(originalRequest);
    }
  }

  return Promise.reject(connection);
};

const retryAfterRefresh = (originalRequest: AxiosRequestConfig) =>
  new Promise<AxiosResponse>((resolve, reject) => {
    openRetryConnections.push({
      resolve,
      reject,
      originalRequest,
    });

    if (!isRefreshingToken) {
      isRefreshingToken = true;
      refreshAndResolveOpenCalls();
    }
  });

const refreshAndResolveOpenCalls = async () => {
  if (isDev()) {
    console.info('Auth - refreshing token');
  }

  try {
    const response = await Auth.currentSession();

    const auth = {
      accessToken: response.getAccessToken().getJwtToken(),
      refreshToken: response.getRefreshToken().getToken(),
      idToken: response.getIdToken().getJwtToken(),
    };

    store.store.dispatch(setAuth(auth));

    resolveOpenConnectons(auth.idToken);
  } catch (e) {
    store.store.dispatch(authSignoutStart());
    rejectOpenConnections();
    return;
  }
};

const rejectOpenConnections = () => {
  openRetryConnections.forEach(({ reject }) => {
    reject();
  });

  openRetryConnections = [];

  isRefreshingToken = false;
};

const resolveOpenConnectons = async (token: string) => {
  openRetryConnections.forEach(async ({ resolve, originalRequest }) => {
    originalRequest.isRetry = true;
    originalRequest.headers.Authorization = `Bearer ${token}`;
    return resolve(await axios(originalRequest));
  });

  openRetryConnections = [];

  isRefreshingToken = false;
};

export default { success, error };
