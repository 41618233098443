import { Selector } from 'react-redux';

import { Store } from 'store/reducers';

export const getIntl: Selector<
  Store, //
  Store['intl']
> = state => state.intl;

export const getLocale: Selector<
  Store, //
  Store['intl']['locale']
> = state => state.intl.locale;
