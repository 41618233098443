import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

interface StyleProps {
  error?: boolean;
  isFocused?: boolean;
}

export const Type = styled(Typography)<StyleProps>`
  color: ${({ theme }) => theme.palette.neutral02[500]};
`;

export const Title = styled(Typography)<StyleProps>`
  font-weight: ${({ isFocused, error }) =>
    isFocused || error ? 'bold' : 'normal'};
  color: ${({ theme, isFocused, error }) => {
    if (error) {
      return theme.palette.error01[500];
    } else if (isFocused) {
      return theme.palette.action01[400];
    } else {
      return theme.palette.neutral02[400];
    }
  }};
`;

export const ErrorLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.error01[500]};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const errorStyles = css`
  box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.palette.error01[500]};
`;

const focusStyles = css`
  &:hover {
    box-shadow: inset 0px 0px 0px 1px
      ${({ theme }) => theme.palette.action01[300]};
  }

  .Mui-focused {
    box-shadow: inset 0px 0px 0px 2px
      ${({ theme }) => theme.palette.action01[400]};
    background-color: transparent;
  }
`;

export const Wrap = styled.div<
  StyleProps & { fullWidth?: boolean; canClear: boolean }
>`
  box-shadow: inset 0px 0px 0px 1px
    ${({ theme }) => theme.palette.neutral02[500]};
  background-color: ${({ theme }) => theme.palette.neutral01[500]};
  height: 40px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '160px')};
  transition: ${({ theme }) => theme.transitions.create(['box-shadow'])};

  ${({ error }) => (error ? errorStyles : focusStyles)};

  .MuiInputBase-root {
    height: 100%;
    width: 100%;
  }

  .MuiInputBase-root.Mui-disabled {
    box-shadow: inset 0px 0px 0px 1px
      ${({ theme }) => theme.palette.neutral02[500]};
    background-color: ${({ theme }) => theme.palette.neutral02[600]};
    color: ${({ theme }) => theme.palette.neutral02[400]};
  }

  .MuiInputBase-adornedEnd {
    padding-right: ${({ theme, canClear }) =>
      theme.spacing(canClear ? 1 : 3)}px;
  }

  .MuiInputBase-adornedStart {
    padding-left: ${({ theme }) => theme.spacing(3)}px;
    color: ${({ theme }) => theme.palette.neutral02[400]};
  }

  .MuiInputBase-input {
    padding: 0 ${({ theme }) => theme.spacing(3)}px;
    height: 100%;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: ${({ theme }) => theme.palette.neutral02[300]};
    display: flex;
    align-items: center;
  }
`;
