import { AxiosInstance } from 'axios';

import RestApiService from '.';
import {
  SizeAdviceRequestBody,
  SizeAdviceResponseBody,
} from './size-advice.definitions';

const roundedData = (data: SizeAdviceRequestBody) => {
  return {
    age: Math.round(+data.age),
    gender: data.gender,
    height: Math.round(+data.height),
    weight: Math.round(+data.weight),
    sizeBust: Math.round(+data.sizeBust),
    sizeHip: Math.round(+data.sizeHip),
    sizeWaist: Math.round(+data.sizeWaist),
    brandId: data.brandId,
  };
};

export async function getSizeAdvice(
  this: RestApiService<AxiosInstance>,
  advice: SizeAdviceRequestBody,
) {
  const response = await this.api.get<SizeAdviceResponseBody>(
    '/size_recommendations',
    {
      params: roundedData(advice),
    },
  );
  return response;
}
