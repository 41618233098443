import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Model, ModelRequestBody } from 'services/api/model.definitions';

export interface ModelState {
  model?: Model;
  isLoading: boolean;
  hasError: boolean;
}

export const initialState: ModelState = {
  isLoading: false,
  hasError: false,
};

const measurementSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    CREATE_MODEL_START: (state, _action: PayloadAction<ModelRequestBody>) => {
      state.isLoading = true;
      state.hasError = false;
    },
    CREATE_MODEL_FAIL: state => {
      state.isLoading = false;
      state.hasError = true;
      state.model = undefined;
    },
    CREATE_MODEL_SUCCESS: (state, { payload }: PayloadAction<Model>) => {
      state.isLoading = false;
      state.hasError = false;
      state.model = payload;
    },
    RESET_MODEL: state => {
      state.isLoading = false;
      state.hasError = false;
      state.model = undefined;
    },
  },
});

export default measurementSlice;

export const {
  CREATE_MODEL_START: createModelStart,
  CREATE_MODEL_FAIL: createModelFail,
  CREATE_MODEL_SUCCESS: createModelSuccess,
  RESET_MODEL: resetModel,
} = measurementSlice.actions;
